import { Controller } from "stimulus"
import agendamentoApi from "../services/agendamento_api"

export default class extends Controller {
  static targets = ['qualificacaoAssunto', 'assuntoClassificado']

  connect() {
    this.disableSelect();
  }

  async classify(event) {
    const { value } = event.target;
    try {
      if (value.length > 0) {
        const { aceitavel, assuntoId , assunto } = await agendamentoApi.classificarAssunto(value);

        assunto.assunto_id = assuntoId;
        this.assuntoClassificadoTarget.value = JSON.stringify(assunto);
        if (aceitavel && assuntoId) {
          this.selectOption(assuntoId, assunto);
        } else {
          this.ableSelect();
        }
      }
    } catch (e) {
      this.ableSelect();
    }
  }

  preClassify(event){
    this.disableSelect();
    document.querySelector('#agendamento_qualificacao_id option[value=""]').selected = true
  }

  selectOption(assuntoId, assunto) {    
    const optionSelecionada = this.qualificacaoAssuntoTarget.querySelector(`option[value="${assuntoId}"]`);

    if (optionSelecionada) {
      optionSelecionada.selected = true;
    } else {
      this.ableSelect();
    }
  }

  disableSelect() {
    this.qualificacaoAssuntoTarget.readonly = true;
    this.qualificacaoAssuntoTarget.classList.add("disabled");
    this.qualificacaoAssuntoTarget.setAttribute("aria-disabled", true);
  }

  ableSelect() {
    this.qualificacaoAssuntoTarget.readonly = false;
    this.qualificacaoAssuntoTarget.classList.remove("disabled");
    this.qualificacaoAssuntoTarget.setAttribute("aria-disabled", false);
  }
}
