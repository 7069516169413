import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['template']

  connect() {
    this.handleMutations()
  }

  handleMutations() {
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (mutation.type === 'childList') {
          $('.money').mask('#.##0,00', { reverse: true });
        }
      })
    })

    observer.observe(this.element, { childList: true })
  }

  add() {
    const templateEl = document.getElementById('bem')
    templateEl.content.querySelector(".btn-remove").disabled = false

    let content = templateEl.innerHTML
    content = content.replace(/BEM_ID/, new Date().valueOf())

    templateEl.closest(".collection").insertAdjacentHTML('beforeend', content)
  }

  remove(event) {
    const el = event.currentTarget.closest('.collection-item')
    el.remove()
  }

  get template() {
    return this.targets.templateTarget
  }
}
