import axios from 'axios';

export default {
  async classificarAssunto(texto) {
    const params = { texto };
    const urlPath = `/agendamentos/classificar_assunto`;

    return await this.getResource(urlPath, params)
  },

  async getResource(urlPath, params) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };

    try {
      const response = await axios.get(urlPath, { 
          params,
          headers 
        })
      
      if (response.status === 200) {
        const { data } = response;

        return { 
          aceitavel: data.aceitavel,
          assuntoId: data.assunto_id,
          assunto: data.assunto,
        };
      } else {
        throw new Error('Erro ao processar a solicitação');
      }
    } catch (error) {
      throw new Error(`Erro ao processar a solicitação: ${error.message}`);
    }
  }
}